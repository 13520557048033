import React from 'react'
import './mediaAwards.css'
import MediaSecondLayerImage1 from "../../images/awardImg1.png"
import MediaSecondLayerImage2 from "../../images/awardImg2.png"
import MediaSecondLayerImage3 from "../../images/awardImg3.png"
import MediaSecondLayerChev1 from "../../images/vector-63.svg"
import MediaSecondLayerimg4 from "../../images/mask-group@2x.png"
import MediaSecondLayerChev2 from "../../images/vector-177.svg"
import MediaSecondLayerChev3 from "../../images/vector-06.svg"

export default function MediaAwards() {
  return (
    <div>
      <div className="mediaAwards-blog-34">
      <div className="mediaAwards-blog-34-inner">
              <div className="mediaAwards-heading-parent">
                <div className="mediaAwards-heading">Awards</div>
                <div className="mediaAwards-button3">
                  <div className="mediaAwards-button-child" />
                  <img className="mediaAwards-button-item" alt="" src={MediaSecondLayerChev2} />
                  <div className="mediaAwards-see-more-parent">
                    <div className="mediaAwards-see-more1">See More</div>
                    <img className="mediaAwards-group-child" alt="" src={MediaSecondLayerChev3} />
                  </div>
                </div>
              </div>
            </div>
            <div className="mediaAwards-content3">
              <div className="mediaAwards-row">
                <div className="mediaAwards-card">
                  <div className="mediaAwards-placeholder-image-parent">
                    <img className="mediaAwards-placeholder-image-icon" alt="" src={MediaSecondLayerImage1}/>
                    <div className="mediaAwards-mask-group-parent">
                      <img className="mediaAwards-mask-group-icon" alt="" src={MediaSecondLayerimg4}/>
                      
                      <div className="mediaAwards-feb">
                        <p className="mediaAwards-p">16</p>
                        <p className="mediaAwards-feb1">Feb</p>
                      </div>
                    </div>
                  </div>
                  <div className="mediaAwards-content4">
                    <div className="mediaAwards-content5">
                      <div className="mediaAwards-text2">CIC Holdings PLC</div>
                      <div className="mediaAwards-heading2">
                        Gold – STING Corporate Accountability Index 2016
                      </div>
                      <div className="mediaAwards-button7">
                        <div className="mediaAwards-button-child1" />
                        <div className="mediaAwards-read-more-parent">
                          <div className="mediaAwards-read-more1">Read More</div>
                          <img
                            className="mediaAwards-vector-icon"
                            alt=""
                            src={MediaSecondLayerChev1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mediaAwards-card">
                  <div className="mediaAwards-placeholder-image-parent">
                    <img
                      className="mediaAwards-placeholder-image-icon"
                      alt=""
                      src={MediaSecondLayerImage2}
                    />
                    <div className="mediaAwards-mask-group-parent">
                      <img
                        className="mediaAwards-mask-group-icon"
                        alt=""
                        src={MediaSecondLayerimg4}
                      />
                      <div className="mediaAwards-feb">
                        <p className="mediaAwards-p">08</p>
                        <p className="mediaAwards-feb1">Feb</p>
                      </div>
                    </div>
                  </div>
                  <div className="mediaAwards-content4">
                    <div className="mediaAwards-content5">
                      <div className="mediaAwards-text2">Chemanex PLC</div>
                      <div className="mediaAwards-heading2">
                        1st Runner Up Manufacturing Sector – SAFA Awards 2014
                      </div>
                      <div className="mediaAwards-button7">
                        <div className="mediaAwards-button-child1" />
                        <div className="mediaAwards-read-more-parent">
                          <div className="mediaAwards-read-more1">Read More</div>
                          <img
                            className="mediaAwards-vector-icon"
                            alt=""
                            src={MediaSecondLayerChev1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mediaAwards-card">
                  <div className="mediaAwards-placeholder-image-parent">
                    <img className="mediaAwards-placeholder-image-icon" alt="" src={MediaSecondLayerImage3}/>
                    <div className="mediaAwards-mask-group-parent">
                      <img
                        className="mediaAwards-mask-group-icon"
                        alt=""
                        src={MediaSecondLayerimg4}
                      />
                      <div className="mediaAwards-feb">
                        <p className="mediaAwards-p">08</p>
                        <p className="mediaAwards-feb1">Feb</p>
                      </div>
                    </div>
                  </div>
                  <div className="mediaAwards-content4">
                    <div className="mediaAwards-content5">
                      <div className="mediaAwards-text2">CIC Agri Businesses (Private) Limited</div>
                      <div className="mediaAwards-heading2">
                        Top 10 Award Winner – 2011 – Achievement of Industrial Excellence -Ceylon National Chamber of Industries.
                      </div>
                      <div className="mediaAwards-button7">
                        <div className="mediaAwards-button-child1" />
                        <div className="mediaAwards-read-more-parent">
                          <div className="mediaAwards-read-more1">Read More</div>
                          <img
                            className="mediaAwards-vector-icon"
                            alt=""
                            src={MediaSecondLayerChev1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}
