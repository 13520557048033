import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import MediaMainBanner from "../templates/MediaRoom/mediaMainBanner"
import MediaSecondLayer from "../templates/MediaRoom/mediaSecondLayer"
import MediaStayInformed from "../templates/MediaRoom/mediaStayInformed"
import MediaAwards from "../templates/MediaRoom/mediaAwards"
import MediaUpcomingEvents from "../templates/MediaRoom/mediaUpcomingEvents"


export default function MediaRoom() {
  return (
    <div>
      <Header />
      <MediaMainBanner />
      <MediaSecondLayer />
      <MediaStayInformed />
      <MediaUpcomingEvents />
      <MediaAwards />
      <Footer />
    </div>
  )
}
