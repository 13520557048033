import React from 'react'
import './mediaUpcomingEvents.css'
import EventLayerChev2 from "../../images/vector-63.svg"

export default function MediaUpcomingEvents() {
  return (
    <div className="upcomingEvents-event-2">
      <div className="upcomingEvents-content62">
              <div className="upcomingEvents-events">Upcoming Events</div>
              <div className="upcomingEvents-content63">
                <div className="upcomingEvents-card27">
                  <div className="upcomingEvents-date">
                    <div className="upcomingEvents-day">Fri</div>
                    <b className="upcomingEvents-day-numbers">09</b>
                    <div className="upcomingEvents-day">Feb 2024</div>
                  </div>
                  <div className="upcomingEvents-divider" />
                  <div className="upcomingEvents-content64">
                    <div className="upcomingEvents-content65">
                      <div className="upcomingEvents-event-title">
                        <div className="upcomingEvents-heading31">Event title heading</div>
                      </div>
                    </div>
                    <div className="upcomingEvents-text85">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. `}</div>
                  </div>
                  <div className="upcomingEvents-button99">
                    <div className="upcomingEvents-button-child23" />
                    <div className="upcomingEvents-read-more-parent">
                      <div className="upcomingEvents-read-more1">Read More</div>
                      <img className="upcomingEvents-vector-icon" alt="" src={ EventLayerChev2} />
                    </div>
                  </div>
                </div>
                <div className="upcomingEvents-card27">
                  <div className="upcomingEvents-date">
                    <div className="upcomingEvents-day">Fri</div>
                    <b className="upcomingEvents-day-numbers">09</b>
                    <div className="upcomingEvents-day">Feb 2024</div>
                  </div>
                  <div className="upcomingEvents-divider" />
                  <div className="upcomingEvents-content64">
                    <div className="upcomingEvents-content65">
                      <div className="upcomingEvents-event-title">
                        <div className="upcomingEvents-heading31">Event title heading</div>
                      </div>
                    </div>
                    <div className="upcomingEvents-text85">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. `}</div>
                  </div>
                  <div className="upcomingEvents-button99">
                    <div className="upcomingEvents-button-child23" />
                    <div className="upcomingEvents-read-more-parent">
                      <div className="upcomingEvents-read-more1">Read More</div>
                      <img className="upcomingEvents-vector-icon" alt="" src={EventLayerChev2}/>
                    </div>
                  </div>
                </div>
                <div className="upcomingEvents-card27">
                  <div className="upcomingEvents-date">
                    <div className="upcomingEvents-day">Fri</div>
                    <b className="upcomingEvents-day-numbers">09</b>
                    <div className="upcomingEvents-day">Feb 2024</div>
                  </div>
                  <div className="upcomingEvents-divider" />
                  <div className="upcomingEvents-content64">
                    <div className="upcomingEvents-content65">
                      <div className="upcomingEvents-event-title">
                        <div className="upcomingEvents-heading31">Event title heading</div>
                      </div>
                    </div>
                    <div className="upcomingEvents-text85">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. `}</div>
                  </div>
                  <div className="upcomingEvents-button99">
                    <div className="upcomingEvents-button-child23" />
                    <div className="upcomingEvents-read-more-parent">
                      <div className="upcomingEvents-read-more1">Read More</div>
                      <img className="upcomingEvents-vector-icon" alt="" src={EventLayerChev2}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}
